<template>
  <v-container
    fluid fill-height
  >
    <v-row
      v-if="!shopOTP"
    >
      <v-col
        cols="12"
        sm="4"
        md="3"
        lg="2"
      >
        <v-sheet
          rounded="lg"
        >
          <v-card
            elevation="0"
            class="pb-0"
          >
            <v-card-text
              class="pb-0"
            >
              <v-row
                align="end"
                class="fill-height"
              >
                <v-col
                  align-self="start"
                  class="pa-0"
                  cols="12"
                >
                  <v-avatar
                    class="profile"
                    color="grey"
                    tile
                    size="100%"
                  >
                    <v-img :src="avartarUrl"
                           contain
                    />
                  </v-avatar>
                </v-col>
                <v-col class="py-0 pt-3 px-2">
                  <h4>
                    Marcus Obrien
                  </h4>
                  <p class="caption">Network Engineer</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>

      <v-col
        cols="12"
        sm="8"
        md="9"
        lg="10"
      >
        <v-sheet
          color="white"
          min-height="70vh"
          rounded="lg"
        >
          <profile-component
            :shareable="$route.params.shareable"
          />
        </v-sheet>
      </v-col>

      <v-col
        v-if="false"
        cols="12"
        sm="4"
        md="2"
      >
        <v-sheet
          rounded="lg"
          min-height="268"
        >
          <!--  -->
        </v-sheet>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6" md="4">
        <v-otp-input
          label="OTP"
          :num-inputs="6"
          input-type="text"
          separator="-"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfileComponent from '@/components/profile/ProfileComponent.vue'

export default {
  name: 'ProfileView',
  components: { ProfileComponent },
  data: () => ({
    loading: false,
    shopOTP: true,
    snackbar: false,
    snackbarColor: 'default',
    otp: '',
    text: '',
    expectedOtp: '133707'
  }),
  computed: {
    avartarUrl () {
      const style = 'avataaars'
      // Ersetzen Sie :style durch einen der verfügbaren Avatar-Stile, zum Beispiel human, identicon, gridy, bottts, avataaars oder jdenticon.
      const seed = Date.now() // Oder Math.random().toString(36).substring(2)
      const url = `https://avatars.dicebear.com/api/${style}/${seed}.svg`
      return url
    }
  },
  methods: {
    onFinish (rsp) {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.snackbarColor = (rsp === this.expectedOtp) ? 'success' : 'warning'
        this.text = `Processed OTP with "${rsp}" (${this.snackbarColor})`
        this.snackbar = true
      }, 3500)
    }
  }
}
</script>

<style scoped>

</style>
