import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: 'black' // Setzen Sie hier Ihre gewünschte Hex-Farbe für die 'primary'-Farbe
      }
      // Optional: Fügen Sie eine benutzerdefinierte 'primary'-Farbe für den dunklen Modus hinzu
      //   dark: {
      //     primary: '#1976D2', // Setzen Sie hier Ihre gewünschte Hex-Farbe für die 'primary'-Farbe im dunklen Modus
      // }
    }
  }
})
