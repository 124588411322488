<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="4"
        md="3"
        lg="2"
      >
        <v-sheet
          rounded="xl"
          class="rounded-xl pa-4"
        >
          <v-card
            elevation="0"
            class="pb-0"
          >
            <v-card-text
              class="pb-0"
            >
              <v-row
                align="end"
                class="fill-height"
              >
                <v-col
                  align-self="start"
                  class="pa-0"
                  cols="12"
                >
                  <v-avatar
                    class="profile"
                    color="grey"
                    tile
                    size="100%"
                  >
                    <v-img :src="avartarUrl"
                           contain
                    />
                  </v-avatar>
                </v-col>
                <v-col class="py-0 pt-3 px-2">
                  <h4>
                    {{ AuthUser.first_name }} {{ AuthUser.last_name }}
                  </h4>
                  <p class="caption">Software Engineer</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>

      <v-col
        cols="12"
        sm="8"
        md="9"
        lg="10"
      >
        <v-sheet
          elevation="2"
          class="rounded-xl pa-4"
          color="white"
          min-height="70vh"
        >
          <v-card
            elevation="0"
          >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    Hallo&nbsp;<strong>{{ AuthUser.first_name }} {{ AuthUser.last_name }}</strong>,<br>füge deinem Profil deine Skills
                    hinzu.
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-responsive max-width="800">
                      <v-text-field
                        clearable
                        v-model="search"
                        append-outer-icon="mdi-tag-plus-outline"
                        label="Skill hinzufügen"
                        dense
                        flat
                        hide-details
                        rounded
                        solo-inverted
                        color="#cc0000"
                      ></v-text-field>
                    </v-responsive>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-text>
              <v-expand-transition>
                <v-card v-if="!!search"
                        outlined
                        class="rounded-xl"
                >
                  <v-card-title class="caption">
                    Skills
                  </v-card-title>
                  <v-card-text>
                    <skill-chip
                      v-for="skill in filteredSkillItems"
                      :key="'chip_' + skill.skill_id"
                      :skillId="skill.skill_id"
                      :profileId="profileId"
                    />
                  </v-card-text>
                </v-card>
              </v-expand-transition>
            </v-card-text>
            <v-card-text>
              <!--              <div class="d-flex flex-wrap flex-fill align-baseline">-->
              <div class="d-flex flex-wrap justify-center">
                <template>
                  <v-scale-transition
                    :appear="true"
                    mode="out-in"
                    v-for="profileSkillItem in profileSkillItemsSortedByRating"
                    :key="profileSkillItem.skill_id"
                  >
                    <skill-profile-item
                      :key="'ski' + profileSkillItem.skill_id"
                      :profileId="profileSkillItem.profile_id"
                      :skillId="profileSkillItem.skill_id"
                    />
                  </v-scale-transition>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>

      <v-col
        v-if="false"
        cols="12"
        sm="4"
        md="2"
      >
        <v-sheet
          rounded="lg"
          min-height="268"
        >
          <!--  -->
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
      >
        <v-dialog>

        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SkillProfileItem from '@/components/Skill/SkillProfileItem.vue'
import SkillChip from '@/components/Skill/SkillChip.vue'

export default {
  name: 'ProfileEditComponent',
  components: {
    SkillChip,
    SkillProfileItem
  },
  props: {
    profileId: {
      type: String,
      default: '',
      useDefaultForNull: false
    }
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    AuthUser () {
      return this.$store.getters.AuthUser
    },
    profile () {
      return this.$store.getters.ProfileItemById(this.profileId)
    },
    profileItems () {
      return this.$store.getters.ProfileItemItems.filter(item => item.profile_id === this.profileId)
    },
    profileSkillItems () {
      return this.$store.getters.ProfileSkillItems.filter(item => item.profile_id === this.profileId).map(item => ({
        ...item,
        uniqueKey: Date.now() + Math.random()
      }))
    },
    profileSkillItemsSortedByRating () {
      // Erstellen Sie eine Kopie des Arrays, um das Original nicht zu verändern
      const itemsCopy = [...this.profileSkillItems]

      // Sortieren Sie die Kopie nach der 'name'-Eigenschaft
      return itemsCopy.sort((a, b) => {
        if (a.rating > b.rating) {
          return -1
        }
        if (a.rating < b.rating) {
          return 1
        }
        return 0
      })
    },
    profileSkillItemsIds () {
      const ids = []
      this.profileSkillItems.forEach((item) => {
        ids.push(item.skill_id)
      })
      return ids
    },
    filteredSkillItems () {
      const max = 100
      let count = 0
      return this.$store.getters.SkillItems.filter((item) => {
        if (count < max && !!this.search && !this.profileSkillItemsIds.includes(item.skill_id) && String(item.skill_label).toLowerCase().includes(String(this.search).toLowerCase())) {
          count++
          return true
        } else {
          return false
        }
      })
    },
    avartarUrl () {
      const style = 'avataaars'
      const seed = Date.now()
      return `https://avatars.dicebear.com/api/${style}/${seed}.svg`
    }
  }
}
</script>

<style scoped>

</style>
