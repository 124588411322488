import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      user_id: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: null,
      address: null,
      zip: null,
      user_status_id: null,
      country: null,
      user_created: null
    },
    token: null,
    alerts: [],
    alertsCounter: 0,
    MessageItems: [],
    MessageStatusItems: [],
    ProfileItems: [],
    ProfileItemItems: [],
    ProfileItemDataItems: [],
    ProfileItemTypeItems: [],
    ProfileSkillItems: [],
    ShareableUrlItems: [],
    ShareableUrlTypeItems: [],
    SkillItems: [],
    SkillGroupItems: [],
    SkillSkillGroupItems: [],
    SkillSkillTypeItems: [],
    SkillTypeItems: [],
    UserItems: [],
    UserStatusItems: [],
    VisitedStatsItems: []
  },
  getters: {
    isAuth (state) {
      return state.token !== null
    },
    AuthUser (state) {
      return state.user
    },
    MessageItems (state) {
      return state.MessageItems
    },
    MessageItemById: state => id => state.MessageItems.find(el => el.message_id === id),
    MessageStatusItems (state) {
      return state.MessageStatusItems
    },
    MessageStatusItemById: state => id => state.MessageStatusItems.find(el => el.message_status_id === id),
    ProfileItems (state) {
      return state.ProfileItems
    },
    ProfileItemById: state => id => state.ProfileItems.find(el => el.profile_id === id),
    ProfileItemItems (state) {
      return state.ProfileItemItems
    },
    ProfileItemItemById: state => id => state.ProfileItemItems.find(el => el.profile_item_id === id),
    ProfileItemDataItems (state) {
      return state.ProfileItemDataItems
    },
    ProfileItemDataItemById: state => id => state.ProfileItemDataItems.find(el => el.profile_item_data_id === id),
    // ProfileItemDataItemDefaultByProfileId: state => id => state.ProfileItemDataItems.find((el) => {
    //   if(el.profile_item_data_id === id){
    //
    //   }
    // }),
    ProfileItemTypeItems (state) {
      return state.ProfileItemTypeItems
    },
    ProfileItemTypeItemById: state => id => state.ProfileItemTypeItems.find(el => el.profile_item_type_id === id),
    ProfileSkillItems (state) {
      return state.ProfileSkillItems
    },
    ProfileSkillItemById: state => (profileId, skillId) => state.ProfileSkillItems.find(el => el.profile_id === profileId && el.skill_id === skillId),
    ShareableUrlItems (state) {
      return state.ShareableUrlItems
    },
    ShareableUrlItemById: state => id => state.ShareableUrlItems.find(el => el.shareable_url_id === id),
    ShareableUrlTypeItems (state) {
      return state.ShareableUrlTypeItems
    },
    ShareableUrlTypeItemById: state => id => state.ShareableUrlTypeItems.find(el => el.shareable_url_type_id === id),
    SkillItems (state) {
      return state.SkillItems
    },
    SkillItemById: state => id => state.SkillItems.find(el => el.skill_id === id),
    SkillGroupItems (state) {
      return state.SkillGroupItems
    },
    SkillGroupItemById: state => id => state.SkillGroupItems.find(el => el.skill_group_id === id),
    SkillSkillGroupItems (state) {
      return state.SkillSkillGroupItems
    },
    SkillSkillGroupItemById: state => id => state.SkillSkillGroupItems.find(el => el.skill_id === id),
    SkillSkillTypeItems (state) {
      return state.SkillSkillTypeItems
    },
    SkillSkillTypeItemById: state => id => state.SkillSkillTypeItems.find(el => el.skill_id === id),
    SkillTypeItems (state) {
      return state.SkillTypeItems
    },
    SkillTypeItemById: state => id => state.SkillTypeItems.find(el => el.skill_type_id === id),
    UserItems (state) {
      return state.UserItems
    },
    UserItemById: state => id => state.UserItems.find(el => el.user_id === id),
    UserStatusItems (state) {
      return state.UserStatusItems
    },
    UserStatusItemById: state => id => state.UserStatusItems.find(el => el.user_status_id === id),
    VisitedStatsItems (state) {
      return state.VisitedStatsItems
    },
    VisitedStatsItemById: state => id => state.VisitedStatsItems.find(el => el.visited_stat_id === id)
  },
  mutations: {
    setItems: function (state, data) {
      if (data.message_items) {
        state.MessageItems = data.message_items
      }
      if (data.message_item) {
        const index = state.MessageItems.findIndex(el => el.message_id === data.message_item.message_id)
        if (index === -1) {
          state.MessageItems.push(data.message_item)
        } else {
          Object.assign(state.MessageItems[index], data.message_item)
        }
      }
      if (data.message_status_items) {
        state.MessageStatusItems = data.message_status_items
      }
      if (data.message_status_item) {
        const index = state.MessageStatusItems.findIndex(el => el.message_status_id === data.message_status_item.message_status_id)
        if (index === -1) {
          state.MessageStatusItems.push(data.message_status_item)
        } else {
          Object.assign(state.MessageStatusItems[index], data.message_status_item)
        }
      }
      if (data.profile_items) {
        state.ProfileItems = data.profile_items
      }
      if (data.profile_item) {
        const index = state.ProfileItems.findIndex(el => el.profile_id === data.profile_item.profile_id)
        if (index === -1) {
          state.ProfileItems.push(data.profile_item)
        } else {
          Object.assign(state.ProfileItems[index], data.profile_item)
        }
      }
      if (data.profile_item_items) {
        state.ProfileItemItems = data.profile_item_items
      }
      if (data.profile_item_item) {
        const index = state.ProfileItemItems.findIndex(el => el.profile_item_id === data.profile_item_item.profile_item_id)
        if (index === -1) {
          state.ProfileItemItems.push(data.profile_item_item)
        } else {
          Object.assign(state.ProfileItemItems[index], data.profile_item_item)
        }
      }
      if (data.profile_item_data_items) {
        state.ProfileItemDataItems = data.profile_item_data_items
      }
      if (data.profile_item_data_item) {
        const index = state.ProfileItemDataItems.findIndex(el => el.profile_item_data_id === data.profile_item_data_item.profile_item_data_id)
        if (index === -1) {
          state.ProfileItemDataItems.push(data.profile_item_data_item)
        } else {
          Object.assign(state.ProfileItemDataItems[index], data.profile_item_data_item)
        }
      }
      if (data.profile_item_type_items) {
        state.ProfileItemTypeItems = data.profile_item_type_items
      }
      if (data.profile_item_type_item) {
        const index = state.ProfileItemTypeItems.findIndex(el => el.profile_item_type_id === data.profile_item_type_item.profile_item_type_id)
        if (index === -1) {
          state.ProfileItemTypeItems.push(data.profile_item_type_item)
        } else {
          Object.assign(state.ProfileItemTypeItems[index], data.profile_item_type_item)
        }
      }
      if (data.profile_skill_items) {
        state.ProfileSkillItems = data.profile_skill_items
      }
      if (data.profile_skill_item) {
        const index = state.ProfileSkillItems.findIndex(el => el.profile_id === data.profile_skill_item.profile_id && el.skill_id === data.profile_skill_item.skill_id)
        if (index === -1) {
          state.ProfileSkillItems.push(data.profile_skill_item)
        } else {
          Object.assign(state.ProfileSkillItems[index], data.profile_skill_item)
        }
      }
      if (data.shareable_url_items) {
        state.ShareableUrlItems = data.shareable_url_items
      }
      if (data.shareable_url_item) {
        const index = state.ShareableUrlItems.findIndex(el => el.shareable_url_id === data.shareable_url_item.shareable_url_id)
        if (index === -1) {
          state.ShareableUrlItems.push(data.shareable_url_item)
        } else {
          Object.assign(state.ShareableUrlItems[index], data.shareable_url_item)
        }
      }
      if (data.shareable_url_type_items) {
        state.ShareableUrlTypeItems = data.shareable_url_type_items
      }
      if (data.shareable_url_type_item) {
        const index = state.ShareableUrlTypeItems.findIndex(el => el.shareable_url_type_id === data.shareable_url_type_item.shareable_url_type_id)
        if (index === -1) {
          state.ShareableUrlTypeItems.push(data.shareable_url_type_item)
        } else {
          Object.assign(state.ShareableUrlTypeItems[index], data.shareable_url_type_item)
        }
      }
      if (data.skill_items) {
        state.SkillItems = data.skill_items
      }
      if (data.skill_item) {
        const index = state.SkillItems.findIndex(el => el.skill_id === data.skill_item.skill_id)
        if (index === -1) {
          state.SkillItems.push(data.skill_item)
        } else {
          Object.assign(state.SkillItems[index], data.skill_item)
        }
      }
      if (data.skill_group_items) {
        state.SkillGroupItems = data.skill_group_items
      }
      if (data.skill_group_item) {
        const index = state.SkillGroupItems.findIndex(el => el.skill_group_id === data.skill_group_item.skill_group_id)
        if (index === -1) {
          state.SkillGroupItems.push(data.skill_group_item)
        } else {
          Object.assign(state.SkillGroupItems[index], data.skill_group_item)
        }
      }
      if (data.skill_skill_group_items) {
        state.SkillSkillGroupItems = data.skill_skill_group_items
      }
      if (data.skill_skill_group_item) {
        const index = state.SkillSkillGroupItems.findIndex(el => el.skill_id === data.skill_skill_group_item.skill_id)
        if (index === -1) {
          state.SkillSkillGroupItems.push(data.skill_skill_group_item)
        } else {
          Object.assign(state.SkillSkillGroupItems[index], data.skill_skill_group_item)
        }
      }
      if (data.skill_skill_type_items) {
        state.SkillSkillTypeItems = data.skill_skill_type_items
      }
      if (data.skill_skill_type_item) {
        const index = state.SkillSkillTypeItems.findIndex(el => el.skill_id === data.skill_skill_type_item.skill_id)
        if (index === -1) {
          state.SkillSkillTypeItems.push(data.skill_skill_type_item)
        } else {
          Object.assign(state.SkillSkillTypeItems[index], data.skill_skill_type_item)
        }
      }
      if (data.skill_type_items) {
        state.SkillTypeItems = data.skill_type_items
      }
      if (data.skill_type_item) {
        const index = state.SkillTypeItems.findIndex(el => el.skill_type_id === data.skill_type_item.skill_type_id)
        if (index === -1) {
          state.SkillTypeItems.push(data.skill_type_item)
        } else {
          Object.assign(state.SkillTypeItems[index], data.skill_type_item)
        }
      }
      if (data.user_items) {
        state.UserItems = data.user_items
      }
      if (data.user_item) {
        const index = state.UserItems.findIndex(el => el.user_id === data.user_item.user_id)
        if (index === -1) {
          state.UserItems.push(data.user_item)
        } else {
          Object.assign(state.UserItems[index], data.user_item)
        }
      }
      if (data.user_status_items) {
        state.UserStatusItems = data.user_status_items
      }
      if (data.user_status_item) {
        const index = state.UserStatusItems.findIndex(el => el.user_status_id === data.user_status_item.user_status_id)
        if (index === -1) {
          state.UserStatusItems.push(data.user_status_item)
        } else {
          Object.assign(state.UserStatusItems[index], data.user_status_item)
        }
      }
      if (data.visited_stats_items) {
        state.VisitedStatsItems = data.visited_stats_items
      }
      if (data.visited_stats_item) {
        const index = state.VisitedStatsItems.findIndex(el => el.visited_stat_id === data.visited_stats_item.visited_stat_id)
        if (index === -1) {
          state.VisitedStatsItems.push(data.visited_stats_item)
        } else {
          Object.assign(state.VisitedStatsItems[index], data.visited_stats_item)
        }
      }
    },
    addAlerts: function (state, data) {
      if (data.alerts && Array.isArray(data.alerts) && data.alerts.length > 0) {
        state.alerts = state.alerts.concat(data.alerts)
        state.alertsCounter = parseInt(state.alertsCounter) + 1
      }
    },
    signIn: function (state, data) {
      if (data.user) {
        state.user = data.user
      }
      if (data.token) {
        state.token = data.token
      }
    }
  },
  actions: {
    initMessageItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Message/init/', payload: payload })
    },
    createMessageItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Message/create/', payload: payload })
    },
    readMessageItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Message/read/', payload: payload })
    },
    updateMessageItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Message/update/', payload: payload })
    },
    deleteMessageItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Message/delete/', payload: payload })
    },
    initMessageStatusItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'MessageStatus/init/', payload: payload })
    },
    createMessageStatusItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'MessageStatus/create/', payload: payload })
    },
    readMessageStatusItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'MessageStatus/read/', payload: payload })
    },
    updateMessageStatusItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'MessageStatus/update/', payload: payload })
    },
    deleteMessageStatusItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'MessageStatus/delete/', payload: payload })
    },
    initProfileItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Profile/init/', payload: payload })
    },
    createProfileItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Profile/create/', payload: payload })
    },
    readProfileItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Profile/read/', payload: payload })
    },
    updateProfileItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Profile/update/', payload: payload })
    },
    deleteProfileItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Profile/delete/', payload: payload })
    },
    initProfileItemItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItem/init/', payload: payload })
    },
    createProfileItemItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItem/create/', payload: payload })
    },
    readProfileItemItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItem/read/', payload: payload })
    },
    updateProfileItemItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItem/update/', payload: payload })
    },
    deleteProfileItemItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItem/delete/', payload: payload })
    },
    initProfileItemDataItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemData/init/', payload: payload })
    },
    createProfileItemDataItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemData/create/', payload: payload })
    },
    readProfileItemDataItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemData/read/', payload: payload })
    },
    updateProfileItemDataItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemData/update/', payload: payload })
    },
    deleteProfileItemDataItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemData/delete/', payload: payload })
    },
    initProfileItemTypeItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemType/init/', payload: payload })
    },
    createProfileItemTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemType/create/', payload: payload })
    },
    readProfileItemTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemType/read/', payload: payload })
    },
    updateProfileItemTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemType/update/', payload: payload })
    },
    deleteProfileItemTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileItemType/delete/', payload: payload })
    },
    initProfileSkillItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileSkill/init/', payload: payload })
    },
    createProfileSkillItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileSkill/create/', payload: payload })
    },
    readProfileSkillItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileSkill/read/', payload: payload })
    },
    updateProfileSkillItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileSkill/update/', payload: payload })
    },
    deleteProfileSkillItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ProfileSkill/delete/', payload: payload })
    },
    initShareableUrlItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrl/init/', payload: payload })
    },
    createShareableUrlItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrl/create/', payload: payload })
    },
    readShareableUrlItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrl/read/', payload: payload })
    },
    updateShareableUrlItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrl/update/', payload: payload })
    },
    deleteShareableUrlItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrl/delete/', payload: payload })
    },
    initShareableUrlTypeItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrlType/init/', payload: payload })
    },
    createShareableUrlTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrlType/create/', payload: payload })
    },
    readShareableUrlTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrlType/read/', payload: payload })
    },
    updateShareableUrlTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrlType/update/', payload: payload })
    },
    deleteShareableUrlTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'ShareableUrlType/delete/', payload: payload })
    },
    initSkillItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Skill/init/', payload: payload })
    },
    createSkillItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Skill/create/', payload: payload })
    },
    readSkillItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Skill/read/', payload: payload })
    },
    updateSkillItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Skill/update/', payload: payload })
    },
    deleteSkillItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'Skill/delete/', payload: payload })
    },
    initSkillGroupItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillGroup/init/', payload: payload })
    },
    createSkillGroupItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillGroup/create/', payload: payload })
    },
    readSkillGroupItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillGroup/read/', payload: payload })
    },
    updateSkillGroupItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillGroup/update/', payload: payload })
    },
    deleteSkillGroupItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillGroup/delete/', payload: payload })
    },
    initSkillSkillGroupItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillGroup/init/', payload: payload })
    },
    createSkillSkillGroupItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillGroup/create/', payload: payload })
    },
    readSkillSkillGroupItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillGroup/read/', payload: payload })
    },
    updateSkillSkillGroupItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillGroup/update/', payload: payload })
    },
    deleteSkillSkillGroupItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillGroup/delete/', payload: payload })
    },
    initSkillSkillTypeItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillType/init/', payload: payload })
    },
    createSkillSkillTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillType/create/', payload: payload })
    },
    readSkillSkillTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillType/read/', payload: payload })
    },
    updateSkillSkillTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillType/update/', payload: payload })
    },
    deleteSkillSkillTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillSkillType/delete/', payload: payload })
    },
    initSkillTypeItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillType/init/', payload: payload })
    },
    createSkillTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillType/create/', payload: payload })
    },
    readSkillTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillType/read/', payload: payload })
    },
    updateSkillTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillType/update/', payload: payload })
    },
    deleteSkillTypeItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'SkillType/delete/', payload: payload })
    },
    initUserItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'User/init/', payload: payload })
    },
    createUserItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'User/create/', payload: payload })
    },
    readUserItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'User/read/', payload: payload })
    },
    updateUserItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'User/update/', payload: payload })
    },
    deleteUserItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'User/delete/', payload: payload })
    },
    initUserStatusItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'UserStatus/init/', payload: payload })
    },
    createUserStatusItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'UserStatus/create/', payload: payload })
    },
    readUserStatusItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'UserStatus/read/', payload: payload })
    },
    updateUserStatusItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'UserStatus/update/', payload: payload })
    },
    deleteUserStatusItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'UserStatus/delete/', payload: payload })
    },
    initVisitedStatsItems ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'VisitedStats/init/', payload: payload })
    },
    createVisitedStatsItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'VisitedStats/create/', payload: payload })
    },
    readVisitedStatsItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'VisitedStats/read/', payload: payload })
    },
    updateVisitedStatsItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'VisitedStats/update/', payload: payload })
    },
    deleteVisitedStatsItem ({ commit, dispatch, state }, payload) {
      return dispatch('apiCallPost', { path: 'VisitedStats/delete/', payload: payload })
    },
    signInByToken ({ commit, dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('/Auth/signInByToken/', data).then(function (response) {
          if (response.data.success) {
            commit('signIn', response.data)
            commit('setItems', response.data)
            resolve()
          } else {
            reject(response)
          }
        }, function (response) {
          reject(response)
        })
      })
    },
    signIn ({ commit, dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('/Auth/signIn/', data).then(function (response) {
          if (response.data.success) {
            commit('signIn', response.data)
            commit('setItems', response.data)
            resolve()
          } else {
            reject(response)
          }
        }, function (response) {
          reject(response)
        })
      })
    },
    apiCallPost ({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          Vue.axios.post(payload.path,
            payload.payload, { method: 'post' })
            .then(response => {
              if (response.data.success) {
                commit('setItems', response.data)
                resolve(response.data)
              } else {
                reject(new Error('fail'))
              }
            })
            .catch(err => {
              reject(new Error(err))
            })
        }, 300)
      })
    },
    apiCallGet ({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          Vue.axios.get(payload.path).then(function (response) {
            if (response.data.success) {
              commit('setItems', response.data)
              resolve(response.data)
            } else {
              reject(response)
            }
          }, function (response) {
            reject(response)
          })
        }, 300)
      })
    }
  },
  modules: {
  }
})
