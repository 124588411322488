<template>
  <v-container
    v-if="show"
    fluid
    fill-height
  >
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
        xl="4"
      >
        <v-card
          class="mt-0 pa-4"
          :loading="loading"
        >
          <v-card-title class="headline">SkillsMatch Profil Login</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                :disabled="loading"
                label="Benutzername/E-Mail"
                required
                :rules="[rules.required]"
                v-model="username"
              ></v-text-field>
              <v-text-field
                :disabled="loading"
                label="Passwort"
                type="password"
                required
                :rules="[rules.required]"
                v-model="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions
            class="pr-4 pb-4"
          >
            <v-btn
              :href="urlRegister"
              text
            >
              Registrieren
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="px-7"
              :loading="loading"
              depressed
              color="primary" @click="submit">Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoginComponent',

  data () {
    return {
      show: false,
      valid: false,
      username: '',
      password: '',
      rules: {
        required: (value) => !!value || 'Dieses Feld ist erforderlich.'
      },
      loadingCounter: 0,
      urlRegister: process.env.VUE_APP_URL_REGISTER
    }
  },

  computed: {
    loading () {
      return this.loadingCounter > 0
    }
  },

  mounted () {
    const token = localStorage.getItem('token')
    if (token) {
      localStorage.removeItem('token')
      this.signInByToken(token)
    } else {
      this.show = true
    }
  },

  methods: {

    submit () {
      if (this.$refs.form.validate()) {
        this.setIsLoading()
        setTimeout(() => {
          this.signIn()
        }, 500)
      }
    },

    signIn () {
      const me = this
      this.$store.dispatch('signIn', {
        email: this.username,
        password: this.password
      })
        .then(() => {
          this.setIsNotLoading()
          me.callUrlAfterLogin()
        }).catch(
          () => {
            this.setIsNotLoading()
          }
        )
    },

    signInByToken (token) {
      this.setIsLoading()
      const me = this
      this.$store.dispatch('signInByToken', { token: token })
        .then(() => {
          this.setIsNotLoading()
          me.callUrlAfterLogin()
        }).catch(
          () => {
            this.setIsNotLoading()
          }
        )
    },

    callUrlAfterLogin () {
      this.show = false
      setTimeout(() => {
        let path = ''
        if (this.$route.query.redirect) {
          path = this.$route.query.redirect
        } else {
          const profile = this.$store.getters.ProfileItems[0]
          path = '/profile/edit/' + profile.profile_id
        }
        this.$nextTick(() => {
          this.$router.push(path)
        })
      }, 600)
    },

    setIsLoading () {
      this.loadingCounter += 1
    },
    setIsNotLoading () {
      if (this.loadingCounter > 0) {
        this.loadingCounter -= 1
      }
    }
  }
}
</script>

<style scoped>

</style>
