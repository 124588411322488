<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="500px"
      scrollable
    >
      <!-- ---- -->
      <v-card
        v-if="editedSkill"
        class="elevation-16 mx-auto"
        width="300"
      >
        <v-card-title class="text-h5">
          {{ editedSkill.skill_label }}
        </v-card-title>
        <v-card-text>
          <div class="text-center mt-2">
<!--            <v-rating-->
<!--              v-model="editedSkill.rating"-->
<!--              color="yellow darken-3"-->
<!--              background-color="grey darken-1"-->
<!--              empty-icon="$ratingFull"-->
<!--              half-increments-->
<!--              hover-->
<!--              large-->
<!--            ></v-rating>-->
            <v-rating
              :disabled="loading"
              background-color="grey"
              color="black"
              empty-icon="mdi-circle-outline"
              full-icon="mdi-circle"
              half-icon="mdi-circle-half-full"
              half-increments
              hover
              length="5"
              size="30"
              v-model="profileSkillItem.rating"
              @input="onClickAdd"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
          <v-btn
            text
            @click="closeDialog"
          >
            abbrechen
          </v-btn>
<!--          <v-btn-->
<!--            :loading="loading"-->
<!--            :disabled="loading"-->
<!--            color="primary"-->
<!--            text-->
<!--            @click="onClickAdd"-->
<!--          >-->
<!--            hinzufügen-->
<!--          </v-btn>-->
        </v-card-actions>
      </v-card>
      <!-- ---- -->
      <v-card
        v-if="false"
        :loading="loading"
      >
        <v-card-title>
          <span class="text-h5">Skill hinzufügen</span>
        </v-card-title>
        <v-divider/>
        <v-card-text
          class="pa-0"
          style="height: 250px;"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card
                  elevation="0"
                >
                  <v-card-text>
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-sheet
                                color="primary lighten-4"
                                class="pa-3 font-weight-bold"
                                rounded
                              >{{ editedSkill.skill_label }} ({{ editedSkill.rating }})
                              </v-sheet>
                            </v-col>
                            <v-col cols="8">
                              <v-rating
                                :disabled="loading"
                                background-color="grey lighten-2"
                                color="primary"
                                empty-icon="mdi-star-outline"
                                full-icon="mdi-star"
                                half-icon="mdi-star-half-full"
                                half-increments
                                hover
                                length="5"
                                size="30"
                                v-model="profileSkillItem.rating"
                              />
                            </v-col>
                            <v-col
                              cols="4"
                              class="d-flex justify-end"
                            />
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider/>
        <v-card-actions
          class="pb-5 px-7"
        >
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            text
            small
            @click="closeDialog"
          >
            abbrechen
          </v-btn>
                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      color="primary"
                      depressed
                      small
                      @click="onClickAdd"
                    >
                      hinzufügen
                    </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'DialogAddSkill',
  mounted () {
    this.$root.$on('open-dialog-add-skill', this.openDialog)
  },

  data () {
    return {
      dialog: false,
      editedSkill: {},
      loading: false,
      profileSkillItem: {
        profile_id: '',
        skill_id: '',
        rating: 0,
        assigned_ts: 0
      },
      defaultProfileSkillItem: {
        profile_id: '',
        skill_id: '',
        rating: 0,
        assigned_ts: 0
      }
    }
  },

  methods: {
    openDialog (payload) {
      Object.assign(this.editedSkill, payload.skill)
      this.profileSkillItem.profile_id = payload.profileId
      this.profileSkillItem.skill_id = payload.skill.skill_id
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      setTimeout(() => {
        this.editedSkill = {}
      }, 500)
    },
    onClickAdd () {
      this.loading = true
      this.$store.dispatch('createProfileSkillItem', this.profileSkillItem)
      setTimeout(() => {
        this.dialog = false
        setTimeout(() => {
          this.loading = false
          Object.assign(this.profileSkillItem, this.defaultProfileSkillItem)
        }, 500)
      }, 200)
    }
  }
}
</script>

<style scoped>

</style>
