<template>
  <div>
    <h1>{{ shareable }}</h1>
  </div>
</template>

<script>
export default {
  name: 'ProfileComponent',
  props: {
    shareable: {
      type: String,
      default: '',
      useDefaultForNull: false
    }
  },
  data: () => ({
    loading: false,
    snackbar: false,
    snackbarColor: 'default',
    otp: '',
    text: '',
    expectedOtp: '133707'
  }),
  methods: {
    onFinish (rsp) {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.snackbarColor = (rsp === this.expectedOtp) ? 'success' : 'warning'
        this.text = `Processed OTP with "${rsp}" (${this.snackbarColor})`
        this.snackbar = true
      }, 3500)
    }
  }
}
</script>

<style scoped>

</style>
