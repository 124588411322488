<template>
  <v-app>
    <v-main
      style="background-color: #f3f4f6;"
    >
      <v-fade-transition>
        <router-view/>
      </v-fade-transition>
      <keep-alive include="DialogAddSkill">
        <dialog-add-skill/>
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>

import DialogAddSkill from '@/components/Skill/DialogAddSkill.vue'

export default {
  name: 'App',
  components: { DialogAddSkill },
  data: () => ({
    //
  })
}
</script>

<style>
.v-sheet-border {
  border: 1px solid #cc0000 !important; /* Graue Umrandung */
}
.position-relative {
  position: relative;
}
</style>
