<template>
  <login-component/>
</template>

<script>
import LoginComponent from '@/components/LoginComponent.vue'

export default {
  name: 'LoginView.vue',
  components: { LoginComponent }
}
</script>

<style scoped>

</style>
