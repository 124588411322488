import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

const publicRoutes = [
  'profile', 'login'
]

router.beforeEach((to, from, next) => {
  if (publicRoutes.includes(to.name)) {
    next()
  } else if (store.state.token !== null) {
    next()
  } else {
    if (String(to.fullPath).length > 5) {
      next('/login/?redirect=' + to.fullPath)
    } else {
      next('/login/')
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
