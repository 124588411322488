<template>
  <profile-edit-component
    :profileId="this.$route.params.id"
  />
</template>

<script>
import ProfileEditComponent from '@/components/profile/ProfileEditComponent.vue'

export default {
  name: 'ProfileEditView',
  components: { ProfileEditComponent },

  created () {
    console.log(this.$route.params.id)
  }

}
</script>

<style scoped>

</style>
