<template>
  <v-chip
    class="ma-1"
    @click="openAddSkillDialog"
  >
    {{ item.skill_label }}
  </v-chip>
</template>

<script>
export default {
  name: 'SkillChip',
  props: {
    profileId: {
      type: String,
      default: '',
      useDefaultForNull: false
    },
    skillId: {
      type: String,
      default: '',
      useDefaultForNull: false
    }
  },

  computed: {
    item () {
      return this.$store.getters.SkillItemById(this.skillId)
    }
  },

  methods: {
    openAddSkillDialog () {
      const skill = Object.assign({}, this.item)
      const payload = {
        skill: skill,
        profileId: this.profileId
      }
      console.log(payload)
      this.$root.$emit('open-dialog-add-skill', payload)
    }
  }
}
</script>

<style scoped>

</style>
