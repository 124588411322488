<template>
  <hello-world/>
</template>

<script>
import HelloWorld from '../components/HelloWorld'

export default {
  name: 'HomeView',

  created () {
    this.$router.push('/login/')
  },

  components: {
    HelloWorld
  }
}
</script>
