<template>
  <div>
    <v-sheet
      @dblclick="onDblClick"
      width="260"
      color="#f3f4f6"
      elevation="0"
      class="pa-3 font-weight-bold ma-3 rounded-lg"
    ><p
      class="my-0 py-0 subtitle-2"
    >{{ Skill.skill_label }} ({{ ProfileSkillItem.rating }})</p>
      <template v-if="!showBtnDelete">
        <v-rating
          v-if="!loading"
          background-color="grey"
          color="black"
          empty-icon="mdi-circle-outline"
          full-icon="mdi-circle"
          half-icon="mdi-circle-half-full"
          half-increments
          hover
          size="30"
          length="5"
          v-model="ProfileSkillItem.rating"
          @input="onSaveRating"
        />
        <v-progress-linear
          v-else
          width="40"
          indeterminate
          rounded
          height="6"
          class="my-5"
        />
      </template>
      <div v-else>
      <v-btn
        :loading="loading"
        depressed
        color="red lighten-4"
        small
        class="mt-2"
        @click="onClickDelete"
      >
        löschen
        <v-icon
          right
          small
          color="red darken-4"
        >mdi-delete</v-icon>
      </v-btn>
      </div>
    </v-sheet>
    <v-card v-if="false">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-sheet
                outlined
                color="primary lighten-4"
                elevation="3"
                class="pa-3 font-weight-bold"
                rounded
              >{{ Skill.skill_label }} ({{ ProfileSkillItem.rating }})
                <v-rating
                  v-if="!loading"
                  background-color="primary lighten-2"
                  color="primary"
                  empty-icon="mdi-star-outline"
                  full-icon="mdi-star"
                  half-icon="mdi-star-half-full"
                  half-increments
                  hover
                  length="5"
                  v-model="ProfileSkillItem.rating"
                  @input="onSaveRating"
                />
                <v-progress-linear
                  v-else
                  width="40"
                  indeterminate
                  rounded
                  height="6"
                  class="my-4"
                />
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SkillProfileItem',
  props: {
    profileId: {
      type: String,
      default: '',
      useDefaultForNull: true
    },
    skillId: {
      type: String,
      default: '',
      useDefaultForNull: true
    }
  },
  data () {
    return {
      tempRating: 0,
      loading: false,
      showBtnSave: false,
      showBtnDelete: false
    }
  },

  created () {
  },

  computed: {
    rating () {
      return this.ProfileSkillItem.rating
    },
    Skill () {
      return this.$store.getters.SkillItemById(this.skillId)
    },
    ProfileSkillItem () {
      const item = this.$store.getters.ProfileSkillItemById(this.profileId, this.skillId)
      if (item) {
        return item
      } else {
        return {
          profile_id: '',
          skill_id: '',
          rating: 0,
          assigned_ts: 0
        }
      }
    }
  },

  methods: {
    onSaveRating () {
      this.loading = true
      setTimeout(() => {
        this.$store.dispatch('updateProfileSkillItem', this.ProfileSkillItem)
          .then(() => {
            this.loading = false
            this.$nextTick(() => {
              this.showBtnSave = false
            })
          })
      }, 500)
    },
    onDblClick () {
      this.showBtnDelete = !this.showBtnDelete
    },
    onClickDelete () {
      this.loading = true
      setTimeout(() => {
        this.$store.dispatch('deleteProfileSkillItem', this.ProfileSkillItem)
          .then(() => {
            this.loading = false
            this.$nextTick(() => {
              this.showBtnSave = false
              this.showBtnDelete = false
            })
          })
      }, 500)
    },
    onRatingInput () {
      console.log(this.rating)
      console.log(this.showBtnSave)
      this.showBtnSave = true
      console.log(this.showBtnSave)
    }
  }
}
</script>

<style scoped>

</style>
